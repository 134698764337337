import idID from "antd/locale/id_ID";

/**
 * 中文
 */
const idIDLanguage = {
  ...idID,
  "service.language.chinese": "Cina",
  "service.language.english": "Bahasa Inggris",
  "service.language.french": "Perancis",
  "service.language.japanese": "Jepang",
  "service.language.korean": "Korea",
  "service.language.vietnamese": "Vietnam",
  "service.language.indonesian": "Bahasa Indonesia",
  "service.language.russian": "Rusia",
  "service.language.german": "Jerman",
  "service.language.italian": "Italia",
  "service.language.portuguese": "Portugis",
  "service.language.thai": "Thailand",
  "service.language.spanish": "Spanyol",


  "service.my.order": "Pesanan Saya",
  "service.my.account": "Akun saya",
  "service.my.cost": "Konsumsi Saya",
  "service.logout": "Log out",


  "service.menu.home": "Halaman rumah",
  "service.menu.component": "Komponen",
  "service.menu.workBench": "Workbench",
  "service.menu.manualRecord": "Generasi Rekaman",
  "service.menu.AIRecord": "Generasi Otomatis",
  "service.menu.organManage": "Manajemen Organisasi",
  "service.menu.component.PPT": "PPT",
  "service.menu.Translation": "Generasi terjemahan",


  "service.home.upload": "Unggah konten",
  "service.home.add": "Tambah",
  "service.home.avatar": "avatar",
  "service.home.uploadPhoto": "Memuat naik gambar",
  "service.home.createAvatar": "Buat avatar",

  "service.nodata": "Tidak ada data yang tersedia",
  "service.unknown": "Tidak Diketahui",
  "service.unName": "Tidak Disebutkan Namanya",


  "service.auto.all": "Semua",
  "service.auto.generated": "Dihasilkan",
  "service.auto.editing": "Mengedit",


  "service.auto.list.owner": "pemilik",
  "service.auto.list.updateTime": "Waktu Pembaruan",

  "service.auto.list.status.all": "Semua",
  "service.auto.list.status.generated": "dihasilkan",
  "service.auto.list.status.editing": "Pengeditan",


  "service.insert.ppt": "Impor PPT",
  "service.insert.title": "Menjelaskan",
  "service.insert.line1": "1.Tentang waktu impor: Impor 10 halaman, sekitar 30 menit. Setelah memilih file, waktu impor spesifik akan diminta.",
  "service.insert.line2": "2.Setelah mengimpor, slide tidak mendukung pengeditan. Ini hanya mendukung penambahan angka dan memilih suara digital.",
  "service.insert.line3": "3.Setelah mengimpor PPT, slide dan anotasi akan diekstraksi.",
  "service.insert.line4": "4.Disarankan untuk menggunakan Microsoft Yahei atau Song Style untuk font PPT.",

  "service.record.openCamera": "Nyalakan kamera",
  "service.record.closeCamera": "Matikan kamera",
  "service.record.openMicro": "Nyalakan mikrofon",
  "service.record.closeMicro": "Matikan mikrofon",
  "service.record.enterFullScreen": "Masuk ke layar penuh",
  "service.record.exitFullscreen": "keluar dari layar penuh",
  "service.record.startRecord": "Nyalakan perekaman",
  "service.record.endRecord": "Akhiri perekaman",
  "service.record.rejectedVideo": "Jika Anda menolak otorisasi perekaman, kami tidak akan merekam video tersebut.",
  "service.record.rejectedMicro": "Anda menolak izin mikrofon, silakan periksa perangkat.",
  "service.record.noMicro": "Perangkat mikrofon tidak ditemukan",
  "service.record.microDisabled": "Mikrofon Anda tidak tersedia, silakan periksa perangkatnya.",
  "service.record.wait20": "Harap tunggu 20 detik sebelum selesai.",
  "service.record.checkMicro": "Deteksi perangkat gagal, mohon periksa mikrofonnya.",
  "service.record.checkCarma": "Deteksi perangkat gagal, silakan periksa kameranya.",
  "service.record.rejectedCamera": "Anda menolak izin kamera, silakan periksa perangkatnya.",
  "service.record.noCamera": "Perangkat kamera tidak ditemukan.",
  "service.record.cameraDisabled": "Kamera Anda tidak tersedia. Silakan periksa perangkat.",


  "service.record.list.searchTips": "Silakan masukkan nama karya",
  "service.record.list.owner": "Pemilik",
  "service.record.list.updateTime": "Waktu pembaruan",
  "service.record.list.uploadTime": "Waktu unggah",
  "service.record.list.opr": "Operasi",
  "service.record.list.title": "Menuju",
  "service.record.list.gender": "Seks",


  "service.avatar.createAvatar": "Buat/edit orang digital",
  "service.avatar.gender": "Seks orang digital",
  "service.avatar.female": "perempuan",
  "service.avatar.male": "Pria",
  "service.avatar.uploadType": "Format .jpg .png",
  "service.avatar.upload.rule-1": "Menguasai gambar wajah lengkap, semakin standar foto, semakin baik efek yang dihasilkan",
  "service.avatar.upload.rule-2": "Silakan kirim saya foto wajah depan saya dengan ciri wajah yang jelas, menutupi area bahu. Direkomendasikan ukuran seharusnya tidak melebihi 10MB",
  "service.avatar.upload.rule-3": "Disarankan penggunaan layar hijau untuk latar belakang gambar",


  "service.PPT.create": "Buat/edit PPT",
  "service.PPT.description": "Deskripsi",
  "service.PPT.inputDescription": "Silakan masukkan deskripsi",
  "service.PPT.rebuilding": "Regenerasi...",
  "service.PPT.generateAnOutline": "Menghasilkan Garis Besar...",
  "service.PPT.rebuild": "Regenerasi",
  "service.PPT.next": "Langkah selanjutnya",
  "service.PPT.back": "Kembali",
  "service.PPT.outlineDescription": "Anda dapat mengklik dua kali untuk mengedit garis besar atau menyimpannya apa adanya",
  "service.PPT.chapter": "Bab",
  "service.PPT.language": "Bahasa",
  "service.PPT.pleaseSelectALanguage": "Silakan pilih bahasa",
  "service.PPT.size50": "Ukuran PPT tidak boleh melebihi 50M",
  "service.PPT.add": "Baru",
  "service.PPT.del": "Hapus",
  "service.PPT.demote": "downgrade",
  "service.PPT.selTheme": "Pilih topiknya",


  "service.record.list.digitalVoice": "Buat/Edit Suara Digital",
  "service.record.list.name": "Nama",
  "service.record.list.introduction": "Pendahuluan",
  "service.record.list.voiceGender": "Voice Gender",
  "service.record.list.upload": "Unggah",
  "service.record.list.clickUpload": "Klik Unggah",
  "service.record.list.agree": "Saya telah membaca dan setuju",
  "service.record.list.agreement": "Perjanjian Pembuatan dan Penggunaan Suara Digital",
  "service.record.list.uploadType": "Formatnya adalah .mp3 .wav",
  "service.record.list.inputName": "Silakan masukkan nama",
  "service.record.list.inputIntroduction": "Silakan masukkan pengantar",
  "service.record.list.selectGender": "Silakan pilih jenis kelamin Anda",
  "service.record.list.uploadFile": "Silakan unggah file",
  "service.record.list.pleaseAgree": "Harap setujui perjanjian",
  "service.record.list.generate": "Generate",
  "service.record.list.generateFailed": "Generasi gagal",
  "service.record.list.gender.female": "perempuan",
  "service.record.list.gender.male": "Pria",
  "service.record.list.selectVocal": "Pilih suara digital",
  "service.record.list.selectModel": "Silakan pilih PPT, vokal digital dan orang digital",
  "service.record.list.onPublish": "Melepaskan...",
  "service.record.list.publish": "rilis",
  "service.record.list.requireVideoName": "Nama video adalah bidang yang wajib diisi",
  "service.record.list.videoName": "Nama Video",
  "service.rekaman.daftar.masukkan": "masukkan",
  "service.record.list.ppt.uploadGagal": "Pengunggahan PPT gagal",
  "service.record.list.parsing": "Parsing...",
  "service.record.list.ppt.parseSuccess": "PPT berhasil diurai",
  "service.record.list.ppt.parseFailed": "Penguraian PPT gagal",
  "service.record.list.selectAva": "Pilih avatar",
  "service.record.cameraTip": "Anda belum menyalakan kamera atau mikrofon, apakah Anda ingin melanjutkan?",
  "service.record.videoName": "Nama video",


  "service.cost.amount": "Jumlah yang dapat dikonsumsi",
  "service.cost.type": "Jenis konsumsi",
  "service.cost.pieces": "Jumlah potongan yang dikonsumsi",
  "service.cost.time": "Mengkonsumsi waktu",
  "service.cost.videoGenerate": "Generasi Video",
  "service.cost.DH": "Produksi Manusia Digital",
  "service.cost.DV": "Produksi Suara Digital",
  "service.cost.searchTips": "Silakan pilih sumber konsumsi",
  "service.cost.video": "Video",
  "service.cost.vocal": "Suara",


  "service.order.selRechargeSource": "Tolong pilih sumber isi ulang",
  "service.order.selRechargeType": "Silakan pilih jenis isi ulang",
  "service.order.selPaymentState": "Silakan pilih status pembayaran",
  "service.order.inputID": "Mohon masukkan nomor pesanan",
  "Service.order.recharge.wechat": "WeChat",
  "service.order.recharge.Alipay": "Alipay",
  "service.order.recharge.CloudQuickPass": "Pembayaran Kilat Awan",
  "service.order.payment.success": "dibayar",
  "service.order.payment.failed": "Tidak Dibayar",
  "service.order.invoice": "Faktur",
  "service.order.id": "Nomor pesanan",
  "service.order.rechargeSource": "isi ulang sumber",
  "service.order.rechargeType": "tipe isi ulang",
  "service.order.paymentState": "Status Pembayaran",
  "service.order.num": "Jumlah pesanan",
  "service.order.orderTime": "Waktu pemesanan",
  "service.order.payTime": "Waktu pembayaran",
  "service.order.video": "Video",
  "service.order.digitalVoice": "Suara Digital",


  "service.translation.selectLan": "Silakan pilih bahasa",
  "service.translation.selectVoice": "Silakan pilih suara",
  "service.translation.video": "Terjemahan Video",
  "service.translation.originalLan": "bahasa asli",
  "service.translation.selOriginalLan": "Silakan pilih bahasa asli",
  "service.translation.translLan": "Bahasa Terjemahan",
  "service.translation.selTranslLan": "Silakan pilih bahasa terjemahan",
  "service.translation.uploadVideo": "Unggah Video",
  "service.translation.uploadVideoVail": "Silakan unggah video",
  "service.translation.uploadSuccess": "Upload Berhasil",
  "service.translation.uploadFailed": "Upload gagal",
  "service.translation.generating": "Terjemahan sedang dihasilkan",
  "service.translation.wait": "Mohon tunggu...",


  "service.login.login": "Masuk",
  "service.login.logout": "Keluar",
  "service.login.register": "Daftar",
  "service.login.registerTips": "Daftarkan sebuah akun",

  "service.component.digitalVoice": "Suara Digital",
  "service.component.digitalFigures": "Manusia Digital",
  "service.component.ppt": "Hasilkan PPT",
  "service.component.onGenerate": "Menghasilkan",
  "service.component.timeEstimate": "Anggaran Waktu",
  "service.component.timeEstimateDetail": "Diperkirakan memakan waktu xxx menit dan xxx yuan.",
  "service.component.paySuccess": "Pembayaran yang berhasil",


  "service.common.publishSuccess": "Berhasil dirilis",
  "service.common.deleteSuccess": "Berhasil dihapus",
  "service.common.deleteFail": "Gagal menghapus",
  "service.common.saveSuccess": "Simpan berhasil",
  "service.common.saveFail": "Gagal menyimpan",
  "service.common.success": "Sukses",
  "service.common.Edit": "Mengedit",
  "service.common.confirm": "Konfirmasi",
  "service.common.cancel": "Batalkan",
  "service.common.setting": "Pengaturan",
  "service.common.None": "Tidak Ada",
  "service.common.tip": "Meminta",
  "service.common.title": "judul",
  "service.common.enterTitle": "Silakan masukkan judul",
  "service.common.downloadFailed": "Pengunduhan gagal",


  "service.notice.errorRequest": "请求错误: ",
  "service.notice.timeout": "请求超时，请检查网络或稍后再试",
  "service.notice.request202": "已接受请求，并进入后台排队（异步任务），但未处理完成",
  "service.notice.request203": "请求成功，非授权信息，返回的meta信息不在原始的服务器，而是一个副本",
  "service.notice.request204": "服务器成功处理，但未返回内容",
  "service.notice.request205": "服务器成功处理，客户端（浏览器）应重置页面视图",
  "service.notice.request206": "服务器成功处理了部分GET请求",
  "service.notice.request400": "发出的请求有误（客户端请求语法错误），服务器没有进行新建或修改数据的操作",
  "service.notice.request401": "用户没有权限（令牌、用户名、密码错误）或登录过期",
  "service.notice.request403": "用户得到授权，但访问已被禁止",
  "service.notice.request404": "请求的资源无法找到（资源不存在或URL错误），服务器没有进行操作",
  "service.notice.request405": "客户端请求中的方法被服务器禁止或请求方法错误",
  "service.notice.request406": "客户端请求格式错误或服务端返回内容客户端无法解析",
  "service.notice.request410": "请求的资源被永久删除，且不会再得到",
  "service.notice.request412": "客户端请求信息的先决条件错误",
  "service.notice.request413": "由于请求的实体过大，服务器无法处理，因此拒绝请求",
  "service.notice.request414": "请求的URL过长（URL通常为网址），服务器无法处理",
  "service.notice.request415": "服务器无法处理请求附带的媒体格式",
  "service.notice.request416": "客户端请求的范围无效",
  "service.notice.request422": "当创建一个对象时，发生一个验证错误",
  "service.notice.request500": "服务器发生错误，请检查服务器",
  "service.notice.request501": "服务器不支持请求的功能，无法完成请求",
  "service.notice.request502": "网关错误，从远程服务器接收到了一个无效的响应",
  "service.notice.request503": "服务不可用，服务器暂时过载或维护",
  "service.notice.request504": "网关超时，充当网关或代理的服务器，未及时从远端服务器获取请求",
  "service.notice.request505": "服务器不支持请求的HTTP协议的版本，无法完成处理",
  "service.notice.request604": "登录超时，请重新登录",
  "service.notice.request603": "登录超时，请重新登录",


  "service.username": "nama pengguna",
  "service.email": "Kotak Surat",
  "service.phone": "Nomor ponsel",
  "service.organization": "organisasi",
  "service.OK": "OK",


  "tips.login.notSupport": "Tidak didukung untuk saat ini",
  "tips.login.success": "Login Berhasil",
  "tips.login.fail": "Login gagal",
  "tips.login.acc": "Akun",
  "tips.login.pass": "Kata sandi",
  "tips.login.agreeTips": "Apakah Anda setuju dengan perjanjian privasi pengguna",
  "tips.login.account": "Silakan masukkan akun",
  "tips.login.password": "Silakan masukkan kata sandi",
  "tips.login.loginWithVerify": "Masuk dengan kode verifikasi",
  "tips.login.check": "Lihat",
  "tips.privacy.title": "Perjanjian Privasi Pengguna",
  "tips.update.success": "Berubah dengan sukses",
  "tips.update.error": "Modifikasi gagal",
  "tips.logOff.success": "Anda telah logged keluar",
  "tips.logOff.error": "Pengbatalan gagal",
  "tips.logOff.warning": "Apakah Anda yakin ingin membatalkan?",
  "tips.email.format":" Silakan masukkan alamat email yang benar ",
  "tips.file.size5":" Ukuran berkas tidak dapat melebihi 5M ",
  "tips.logOff.tips":" Setelah log out, tidak dapat dikembalikan. Silakan teruskan dengan hati-hati",
  "tips.psw.length":" Panjang sandi harus diantara 6-20 digit ",
  "tips.psw.chars":" Kata sandi harus mengandung dua jenis karakter: huruf besar dan huruf kecil, angka, dan karakter khusus",
  "tips.psw.rule":" Silakan masukkan kata sandi baru, yang panjang 6-20 karakter dan harus mengandung dua jenis huruf besar dan huruf kecil, angka, dan karakter khusus",
  "tips.download.warning": "Memuat turun, tolong jangan klik lagi!",
  "tips.login.theAccountNumberOrPasswordIsIncorrect": "Akaun atau kesalahan kata sandi!",

  "btn.text.release": "Terbitkan",
  "btn.text.modify": "Ubah",

  "service.user.center": "Pusat Pengguna",
  "service.user.updateAvatar": "Ubah avatar",
  "service.user.avatarFormat": "Gambar harus lebih besar dari 500*500 piksel, mendukung format JPG, JPEG, PNG dan SVG, dan ukurannya tidak boleh melebihi 5M",
  "service.user.nickname": "nama panggilan",
  "service.user.cancelAccount": "Batalkan Akun",
  "service.user.cancelAccountWarning": "Itu tidak dapat dipulihkan setelah keluar, harap berhati-hati",
  "service.user.logOff": "Keluar",
  "service.user.changePsw": "Ubah Sandi",

}

export default idIDLanguage;

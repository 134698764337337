import enUS from "@/locales/en_US";
import zhCN from "@/locales/zh_CN";
import frFR from "@/locales/fr_FR";
import koKR from "@/locales/ko_KR";
import jaJP from "@/locales/ja_JP";
import viVN from "@/locales/vi_VN";
import idID from "@/locales/id_ID";
import esES from "@/locales/es_ES"

export const locales = {
    'en': enUS,
    'zh': zhCN,
    'fr': frFR,
    'ko': koKR,
    'ja': jaJP,
    'vi': viVN,
    'id': idID,
    'es': esES
}

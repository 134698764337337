import koKR from "antd/locale/ko_KR";

/**
 * 韩语
 */
const koLanguage = {
  ...koKR,
  "service.language.chinese": "중국어",
  "service.language.english": "영어",
  "service.language.french": "프랑스어",
  "service.language.japanese": "일본어",
  "service.language.korean": "한국어",
  "service.language.vietnamese": "베트남어",
  "service.language.russian": "러시아어",
  "service.language.german": "독일어",
  "service.language.italian": "이탈리아어",
  "service.language.portuguese": "포르투갈어",
  "service.language.thai": "태국어",
  "service.language.spanish": "스페인어",
  "service.language.indonesian": "인도네시아어",

  "service.my.order": "내 주문",
  "service.my.account": "내 계정",
  "service.my.cost": "나의 소비",
  "service.logout": "로그인 종료",

  "service.menu.home": "첫 페이지",
  "service.menu.component": "구성 요소",
  "service.menu.workBench": "작업대",
  "service.menu.manualRecord": "기록 생성",
  "service.menu.AIRecord": "자동 생성",
  "service.menu.organManage": "조직 관리",
  "service.menu.component.PPT": "PPT",
  "service.menu.Translation": "번역 생성",
  "service.menu.component.outline": "PPT 개요",

  "service.home.upload": "업로드",
  "service.home.add": "추가",
  "service.home.avatar": "프로필 사진",
  "service.home.uploadPhoto": "사진 업로드",
  "service.home.createAvatar": "아바타 만들기",

  "service.nodata": "데이터 없음",
  "service.unknown": "알 수 없음",
  "service.unName": "이름 없음",


  "service.auto.all": "모두",
  "service.auto.generated": "생성됨",
  "service.auto.editing": "편집 중",

  "service.auto.list.status.all": "모두",
  "service.auto.list.status.generated": "생성됨",
  "service.auto.list.status.editing": "편집 중",
  "service.auto.list.owner": "소유자",
  "service.auto.list.updateTime": "업데이트 시간",


  "service.insert.ppt": "PPT 가져오기",
  "service.insert.title": "설명",
  "service.insert.line1": "1. 가져오기 시간 정보: 10페이지를 가져오는 데 약 30min이 소요됩니다.파일을 선택하면 특정 가져오기 시간이 표시됩니다.",
  "service.insert.line2": "2. 가져온 후 슬라이드는 편집을 지원하지 않으며, 디지털 오디오 추가와 디지털 오디오 선택만 지원합니다.",
  "service.insert.line3": "3. PPT를 가져오면 슬라이드와 마크업이 추출됩니다.",
  "service.insert.line4": "4. PPT글꼴은 마이크로소프트 아흑이나 송체를 사용하는 것을 권장한다",


  "service.record.openCamera": "카메라 켜기",
  "service.record.closeCamera": "카메라 끄기",
  "service.record.openMicro": "마이크를 켜다",
  "service.record.closeMicro": "마이크 끄기",
  "service.record.enterFullScreen": "전체 화면",
  "service.record.exitFullscreen": "전체 화면 종료",
  "service.record.startRecord": "녹화 시작",
  "service.record.endRecord": "녹화 종료",
  "service.record.rejectedVideo": "녹화 승인을 하셨고, 저희는 녹화하지 않겠습니다.",
  "service.record.rejectedMicro": "마이크 권한을 거부하셨습니다. 장비를 검사해 주세요.",
  "service.record.noMicro": "마이크 장비를 못 찾았어요",
  "service.record.microDisabled": "당신의 마이크는 사용할 수 없습니다. 장비를 점검해 주세요.",
  "service.record.wait20": "이십초를 기다린 후에 다시 끝내세요.",
  "service.record.checkMicro": "장비 감지 실패, 마이크를 점검해 주세요",
  "service.record.checkCarma": "장비 검사 실패, 카메라 검사해 주세요.",
  "service.record.rejectedCamera": "카메라 권한을 하셨으니 장비를 확인해 주세요.",
  "service.record.noCamera": "카메라 장비를 못 찾았어요",
  "service.record.cameraDisabled": "당신의 카메라는 사용할 수 없어요. 장비를 검사해 주세요.",

  "service.record.list.searchTips": "작품명을 입력하세요",
  "service.record.list.owner": "소유자",
  "service.record.list.updateTime": "업데이트 시간",
  "service.record.list.uploadTime": "업로드 시간",
  "service.record.list.opr": "작업",
  "service.record.list.title": "제목",
  "service.record.list.gender": "성별",

  "service.avatar.createAvatar": "디지털 사용자 만들기 / 편집",
  "service.avatar.gender": "디지털 인간의 성별",
  "service.avatar.female": "여자",
  "service.avatar.male": "남자",
  "service.avatar.uploadType": "다음 형식 .jpg .png",
  "service.avatar.upload.rule-1": "얼굴 전체 사진을 업로드하면 사진이 표준일수록 더 잘 생성된다",
  "service.avatar.upload.rule-2": "본인의 정면, 이목구비가 뚜렷한 사진을 업로드해야 하며 어깨구역을 포괄하며 크기가 10MB를 초과하지 않도록 건의해야 한다",
  "service.avatar.upload.rule-3": "그림 배경은 녹색 스크린을 사용하여 PPT를 만들거나 편집하는 것이 좋습니다.",


  "service.PPT.create": "PPT 만들기 / 편집",
  "service.PPT.description": "설명",
  "service.PPT.inputDescription": "설명을 입력하십시오",
  "service.PPT.rebuilding": "재생성 중...",
  "service.PPT.generateAnOutline": "생성 개요...",
  "service.PPT.rebuild": "재생성",
  "service.PPT.next": "다음",
  "service.PPT.back": "이전",
  "service.PPT.outlineDescription": "두 번 클릭하여 개요를 편집하거나 그대로 계속할 수 있습니다.",
  "service.PPT.chapter": "장",
  "service.PPT.language": "언어",
  "service.PPT.pleaseSelectALanguage": "언어를 선택하십시오",
  "service.PPT.size50": "PPT 크기는 50M을 초과할 수 없습니다.",
  "service.PPT.add": "추가",
  "service.PPT.del": "삭제",
  "service.PPT.demote": "강하",
  "service.PPT.selTheme": "주제 선택",


  "service.record.list.digitalVoice": "디지털 음성 만들기 / 편집",
  "service.record.list.name": "이름",
  "service.record.list.introduction": "소개",
  "service.record.list.voiceGender": "음성 성별",
  "service.record.list.upload": "업로드",
  "service.record.list.clickUpload": "클릭하여 업로드하기",
  "service.record.list.agree": "나는 이미 읽었고 동의했다",
  "service.record.list.agreement": "디지털 음성 생성 및 사용 규약",
  "service.record.list.uploadType": "다음 형식 .mp3 .wav",
  "service.record.list.inputName": "이름을 입력하십시오",
  "service.record.list.inputIntroduction": "간단한 설명을 입력하십시오",
  "service.record.list.selectGender": "성별을 선택하세요",
  "service.record.list.uploadFile": "파일을 업로드하십시오",
  "service.record.list.pleaseAgree": "계약에 동의하십시오",
  "service.record.list.generate": "생성",
  "service.record.list.generateFailed": "생성 실패",
  "service.record.list.gender.female": "여자",
  "service.record.list.gender.male": "남자",
  "service.record.list.selectVocal": "디지털 사운드 선택",
  "service.record.list.selectModel": "PPT, 디지털 음성 및 디지털 사람을 선택하십시오",
  "service.record.list.onPublish": "게시 중...",
  "service.record.list.publish": "게시",
  "service.record.list.requireVideoName": "비디오 이름은 필수 필드입니다.",
  "service.record.list.videoName": "비디오 이름",
  "service.record.list.insert": "삽입",
  "service.record.list.ppt.uploadFailed": "PPT 업로드 실패",
  "service.record.list.parsing": "확인 중...",
  "service.record.list.ppt.parseSuccess": "PPT 해결 성공",
  "service.record.list.ppt.parseFailed": "PPT 해석 완료",
  "service.record.list.selectAva": "아바타 선택",
  "service.record.cameraTip": "카메라 또는 마이크를 켜지 않았습니다. 계속하시겠습니까?",
  "service.record.videoName": "동영상 이름",


  "service.cost.amount": "소모 금액",
  "service.cost.type": "소비 유형",
  "service.cost.pieces": "소모 건수",
  "service.cost.time": "시간 소모",
  "service.cost.videoGenerate": "비디오 생성",
  "service.cost.DH": "디지털인 제작",
  "service.cost.DV": "디지털 사운드 제작",
  "service.cost.searchTips": "소모원을 선택하세요",
  "service.cost.video": "비디오",
  "service.cost.vocal": "소리",


  "service.order.selRechargeSource": "충전 원본을 선택하세요",
  "service.order.selRechargeType": "충전 종류를 선택하세요",
  "service.order.selPaymentState": "결제 상태를 선택하십시오.",
  "service.order.inputID": "주문 번호를 입력하십시오.",
  "service.order.recharge.wechat": "위챗",
  "service.order.recharge.Alipay": "알리페이",
  "service.order.recharge.CloudQuickPass": "클라우드 플래시 결제",
  "service.order.payment.success": "지불됨",
  "service.order.payment.failed": "미지급",
  "service.order.invoice": "영수증을 발급하다",
  "service.order.id": "주문 번호",
  "service.order.rechargeSource": "충전원",
  "service.order.rechargeType": "충전 유형",
  "service.order.paymentState": "지불 상태",
  "service.order.num": "주문 수",
  "service.order.orderTime": "주문 시간",
  "service.order.payTime": "결제 시간",
  "service.order.video": "비디오",
  "service.order.digitalVoice": "디지털 사운드",


  "service.translation.selectLan": "언어를 선택하십시오",
  "service.translation.selectVoice": "사운드를 선택하십시오",
  "service.translation.video": "비디오 번역",
  "service.translation.originalLan": "원시 언어",
  "service.translation.selOriginalLan": "원본 언어를 선택하십시오",
  "service.translation.translLan": "번역 언어",
  "service.translation.selTranslLan": "번역 언어를 선택하십시오",
  "service.translation.uploadVideo": "비디오 업로드",
  "service.translation.uploadVideoVail": "영상 올려주세요",
  "service.translation.uploadSuccess": "업로드 성공",
  "service.translation.uploadFailed": "업로드 실패",
  "service.translation.generating": "번역 생성 중",
  "service.translation.wait": "기다려 주세요...",


  "service.login.login": "로그인",
  "service.login.logout": "종료",
  "service.login.register": "등록",
  "service.login.registerTips": "계정 등록",

  "service.component.digitalVoice": "디지털 사운드",
  "service.component.digitalFigures": "숫자인",
  "service.component.ppt": "PPT 생성",
  "service.component.onGenerate": "생성 중...",
  "service.component.timeEstimate": "시간예산",
  "service.component.timeEstimateDetail": "xxx 분 정도 걸리고 xxx 원 정도들 예정입니다.",
  "service.component.paySuccess": "결제 성공",

  "service.common.publishSuccess": "게시 성공",
  "service.common.deleteSuccess": "삭제 성공",
  "service.common.deleteFail": "삭제 실패",
  "service.common.success": "성공",
  "service.common.Edit": "편집",
  "service.common.confirm": "확인",
  "service.common.cancel": "취소",
  "service.common.setting": "설치",
  "service.common.None": "당분간 없다",
  "service.common.tip": "힌트",
  "service.common.title": "제목",
  "service.common.enterTitle": "제목을 입력하십시오",
  "service.common.theBuildSucceeded": "건설에 성공했습니다",
  "service.common.downloadFailed": "다운로드 실패",


  "service.notice.errorRequest": "요청 오류: ",
  "service.notice.timeout": "요청 시간이 초과되었습니다. 네트워크를 확인하거나 나중에 다시 시도하십시오.",
  "service.notice.request202": "요청을 수락하고 백그라운드 대기 (비동기 작업) 에 들어갔지만 처리가 완료되지 않았습니다.",
  "service.notice.request203": "요청 성공, 비승인 정보, 반환된 meta 정보는 원본 서버가 아닌 복제본입니다.",
  "service.notice.request204": "서버가 성공적으로 처리되었지만 컨텐츠가 반환되지 않았습니다.",
  "service.notice.request205": "서버가 성공적으로 처리되었으므로 클라이언트 (브라우저) 는 페이지 보기를 재설정해야 합니다.",
  "service.notice.request206": "서버가 일부 GET 요청을 성공적으로 처리했습니다.",
  "service.notice.request400": "잘못된 요청 (클라이언트 요청 구문 오류) 이 발생하여 서버가 데이터를 새로 만들거나 수정하지 않았습니다.",
  "service.notice.request401": "사용자에게 권한이 없거나 (토큰, 사용자 이름, 암호 오류) 로그인이 만료되었습니다.",
  "service.notice.request403": "사용자가 승인되었지만 액세스가 차단되었습니다.",
  "service.notice.request404": "요청한 자원을 찾을 수 없음 (자원이 없거나 URL 오류), 서버에서 작동하지 않음",
  "service.notice.request405": "클라이언트 요청의 메서드가 서버에서 차단되었거나 요청 메서드가 잘못되었습니다.",
  "service.notice.request406": "클라이언트 요청 형식 오류 또는 서비스 측 반환 내용 클라이언트가 확인할 수 없음",
  "service.notice.request410": "요청한 자원은 영구적으로 삭제되며 더 이상 얻을 수 없습니다.",
  "service.notice.request412": "클라이언트 요청 정보에 대한 사전 요구 사항 오류",
  "service.notice.request413": "요청된 엔터티가 너무 커서 서버에서 처리할 수 없으므로 요청을 거부합니다.",
  "service.notice.request414": "요청된 URL이 너무 길어서 서버에서 처리할 수 없음",
  "service.notice.request415": "서버에서 요청과 함께 미디어 형식을 처리할 수 없습니다.",
  "service.notice.request416": "잘못된 클라이언트 요청 범위",
  "service.notice.request422": "객체를 만드는 동안 유효성 검사 오류가 발생했습니다",
  "service.notice.request500": "서버에 오류가 발생했습니다. 서버를 확인하십시오.",
  "service.notice.request501": "서버에서 요청된 기능을 지원하지 않아 요청을 완료할 수 없습니다.",
  "service.notice.request502": "원격 서버에서 잘못된 응답을 받은 게이트웨이 오류",
  "service.notice.request503": "서비스를 사용할 수 없음, 일시적인 서버 과부하 또는 유지 관리",
  "service.notice.request504": "게이트웨이 시간 초과, 게이트웨이 또는 프록시 역할을 하는 서버, 원격 서버에서 제때에 요청을 받지 않음",
  "service.notice.request505": "서버가 요청한 HTTP 프로토콜의 버전을 지원하지 않아 처리를 완료할 수 없습니다.",
  "service.notice.request604": "로그인 시간이 초과되었습니다. 다시 로그인하십시오.",
  "service.notice.request603": "로그인 시간이 초과되었습니다. 다시 로그인하십시오.",


  "service.username": "사용자 이름",
  "service.email": "메일박스",
  "service.phone": "핸드폰 번호",
  "service.organization": "조직",
  "service.OK": "확인",

  "tips.login.notSupport": "지원되지 않음",
  "tips.login.success": "로그인 성공",
  "tips.login.fail": "로그인 실패",
  "tips.login.acc": "계정을 입력하십시오.",
  "tips.login.pass": "암호를 입력하십시오.",
  "tips.login.loginWithVerify": "인증 코드를 사용하여 로그인",
  "tips.login.check": "보기",
  "tips.privacy.title": "사용자 개인 정보 보호 프로토콜",
  "tips.update.success": "수정 성공",
  "tips.update.error": "수정 실패",
  "tips.logOff.success": "로그아웃하셨습니다.",
  "tips.logOff.error": "로그아웃 실패",
  "tips.logOff.warning": "로그아웃하시겠습니까?",
  "tips.email.format": "올바른 메일박스를 입력하십시오",
  "tips.file.size5":" 파일 크기는 5M을 초과할 수 없습니다",
  "tips.logOff.tips": "로그아웃 후 복구할 수 없습니다. 조심하십시오.",
  "tips.psw.length":" 암호 길이는 6-20비트 사이여야 합니다",
  "tips.psw.chars": "암호에는 대소문자, 숫자, 특수 문자 중 두 가지가 포함되어야 합니다.",
  "tips.psw.rule": "새 암호를 입력하십시오. 암호 길이는 6-20자리입니다. 대소문자, 숫자, 특수 문자 중 두 가지를 포함해야 합니다.",
  "tips.download.warning": "다운로드 중입니다. 다시 클릭하지 마십시오!",
  "tips.login.theAccountNumberOrPasswordIsIncorrect": "계정 또는 비밀번호 오류!",


  "btn.text.release": "게시",
  "btn.text.modify": "수정",

  "service.user.center": "사용자 센터",
  "service.user.updateAvatar": "프로필 수정",
  "service.user.avatarFormat": "이미지 500*500픽셀 이상이어야 하며 JPG, JPEG, PNG 및 SVG 포맷을 지원하며 크기는 5M을 넘지 않아야 합니다.",
  "service.user.nickname": "닉네임",
  "service.user.cancelAccount": "계정 해지",
  "service.user.cancelAccountWarning": "해제 후 복구할 수 없으니 주의하시기 바랍니다.",
  "service.user.logOff": "해지",
  "service.user.changePsw": "암호 수정",

}

export default koLanguage;

// 和用户相关的状态管理
import { createSlice } from "@reduxjs/toolkit";
import { getUserDetail, userLogin } from "@/apis/user";
import { getUserInfo, setUserInfo, removeInfo } from "@/utils/token";
import { USER_DETAIL } from "@/constants";

/**
 * 保存用户登录状态，用户信息，持久化
 * @type
 */
const userStore = createSlice({
  name: "user",
  // 数据状态
  initialState: {
    data: getUserInfo(),
    userDetail: localStorage.getItem(USER_DETAIL)
      ? JSON.parse(localStorage.getItem(USER_DETAIL))
      : null,
  },
  // 同步修改方法
  reducers: {
    removeUserDetail(state) {
      state.userDetail = null;
      removeInfo();
      localStorage.removeItem(USER_DETAIL);
    },
    saveUserDetail(state, action) {
      console.log(action.payload);
      state.userDetail = action.payload;
      localStorage.setItem(USER_DETAIL, JSON.stringify(action.payload));
    },
    saveUserData (state, action) {
      state.data = action.payload;
      state.userDetail = action.payload.userInfo;
      localStorage.setItem(USER_DETAIL, JSON.stringify(action.payload.userInfo));
      setUserInfo(action.payload);
    },
    removeUserData(state) {
      state.data = null;
      localStorage.removeItem(window.envConfig.ROOT_UER_INFO);
    },
  },
});

// 解构出actions
const { saveUserData, removeUserDetail, saveUserDetail } = userStore.actions;

// 获取reducer函数
const userReducer = userStore.reducer;

/**
 * 登录方法，暂时没有用，组件中要获取res提示信息，直接在组件中发送请求
 * @param loginForm
 * @returns {(function(*): Promise<void>)|*}
 */
// 登录获取token异步方法封装
const fetchLogin = (loginForm) => {
  return async (dispatch) => {
    const res = await userLogin(loginForm);
    if (res && res.data) {
      dispatch(saveUserData && saveUserData(res.data));
    }
  };
};

// 获取个人用户信息异步方法
const fetchUserInfo = () => {
  return async (dispatch) => {};
};

// const fry
export {
  fetchLogin,
  fetchUserInfo,
  saveUserData,
  removeUserDetail,
  saveUserDetail,
};

export default userReducer;

import {createSlice} from "@reduxjs/toolkit";
import {languageAreaMap, navigateLanguage} from "@/utils/language";

const settingStore = createSlice({
    name: "setting",
    // 数据状态
    initialState: {
        locale: languageAreaMap.get(navigateLanguage) ?? "en",
        headerHeight: 72,
    },
    // 同步修改方法
    reducers: {
        changeLanguage(state, action) {
            state.locale = action.payload;
        }
    }
});

// 解构出actions
const {changeLanguage} = settingStore.actions;


const settingReducer = settingStore.reducer;

export {
    changeLanguage,
}
export default settingReducer;

import esES from "antd/locale/es_ES";

/**
 * 西班牙语
 */
const esLanguage = {
  ...esES,
  "service.language.chinese": "Idioma chino",
  "service.language.english": "En inglés",
  "service.language.french": "Francés",
  "service.language.japanese": "Japonés",
  "service.language.korean": "Coreano",
  "service.language.vietnamese": "Vietnamita",
  "service.language.indonesian": "Indonesio",
  "service.language.russian": "Russian",
  "service.language.german": "En alemán",
  "service.language.italian": "Italiano",
  "service.language.portuguese": "Portugués",
  "service.language.thai": "Tailandés",
  "service.language.spanish": "Español",

  "service.my.order": "Mi pedido",
  "service.my.account": "Mi cuenta",
  "service.my.cost": "Mi consumo",
  "service.logout": "salir del inicio de sesión",

  "service.menu.home": "Página de inicio",
  "service.menu.component": "Componente",
  "service.menu.workBench": "Mesa de trabajo",
  "service.menu.manualRecord": "Grabación generada",
  "service.menu.AIRecord": "Generación automática",
  "service.menu.organManage": "Gestión organizacional",
  "service.menu.component.PPT": "PPT",
  "service.menu.Translation": "Translation generation",
  "service.menu.component.outline": "Esquema PPT",


  "service.home.upload": "Cargar contenido",
  "service.home.add": "Añadir",
  "service.home.avatar": "Avatares",
  "service.home.uploadPhoto": "Subir imágenes",
  "service.home.createAvatar": "Crear avatares",

  "service.nodata": "No hay datos por el momento",
  "service.unknown": "Desconocido",
  "service.unName": "Sin nombre",

  "service.auto.all": "Total",
  "service.auto.generated": "Generado",
  "service.auto.editing": "En edición",

  "service.auto.list.owner": "Propietario",
  "service.auto.list.updateTime": "Tiempo de actualización",

  "service.auto.list.status.all": "Todo",
  "service.auto.list.status.generated": "Generado",
  "service.auto.list.status.editing": "En edición",


  "service.insert.ppt": "Importar ppt",
  "service.insert.title": "Descripción",
  "service.insert.line1": "1. Sobre el tiempo de importación: importación de 10 páginas, aproximadamente 30 minutos. después de seleccionar el archivo, se le indicará el tiempo de importación específico",
  "service.insert.line2": "2. Las diapositivas después de la importación no admiten la edición, solo admiten agregar personas digitales y seleccionar personas digitales",
  "service.insert.line3": "3. Después de importar ppt, se extraerán diapositivas y etiquetas",
  "service.insert.line4": "4. La fuente ppt recomienda usar Microsoft YaHei o Song style",


  "service.record.openCamera": "Enciende la cámara",
  "service.record.closeCamera": "Apague la cámara",
  "service.record.openMicro": "Enciende el micrófono",
  "service.record.closeMicro": "Apague el micrófono",
  "service.record.enterFullScreen": "Entra en pantalla completa",
  "service.record.exitFullscreen": "Salir de la pantalla completa",
  "service.record.startRecord": "Abre la grabación",
  "service.record.endRecord": "Termina la grabación",
  "service.record.rejectedVideo": "Usted ha rechazado la autorización de grabación y no vamos a grabar videos",
  "service.record.rejectedMicro": "Usted rechazó los permisos del micrófono, verifique el dispositivo",
  "service.record.noMicro": "No se encontró el dispositivo del micrófono",
  "service.record.microDisabled": "Su micrófono no está disponible, verifique el dispositivo",
  "service.record.wait20": "Por favor, espere Veinte segundos antes de terminar",
  "service.record.checkMicro": "La detección del dispositivo falló, por favor revise el micrófono",
  "service.record.checkCarma": "La detección del dispositivo falló, por favor revise la cámara",
  "service.record.rejectedCamera": "Usted rechazó los permisos de la cámara, por favor revise el dispositivo",
  "service.record.noCamera": "No se encontró el dispositivo de la cámara",
  "service.record.cameraDisabled": "Su cámara no está disponible, verifique el dispositivo",


  "service.record.list.searchTips": "Introduzca el nombre de la obra",
  "service.record.list.owner": "Propietario",
  "service.record.list.updateTime": "Hora de actualización",
  "service.record.list.uploadTime": "Tiempo de carga",
  "service.record.list.opr": "Operación",
  "service.record.list.title": "Título",
  "service.record.list.gender": "Género",


  "service.avatar.createAvatar": "Crear/editar personas digitales",
  "service.avatar.gender": "Género humano digital",
  "service.avatar.female": "Mujeres",
  "service.avatar.male": "Hombres",
  "service.avatar.uploadType": "El formato es .jpg .png",
  "service.avatar.upload.rule-1": "Sube la imagen completa de la cara, cuanto más estándar sea la foto, mejor será el efecto de generación.",
  "service.avatar.upload.rule-2": "Hay que subir una foto clara de mi cara y rasgos faciales, cubriendo el área del hombro, se recomienda que el tamaño no supere los 10 megabytes.",
  "service.avatar.upload.rule-3": "Fondo de la imagen se recomienda usar pantalla verde",


  "service.PPT.create": "Crear/Editar PPT",
  "service.PPT.description": "Descripción",
  "service.PPT.inputDescription": "Por favor, introduzca la descripción",
  "service.PPT.rebuilding": "Se está reconstruyendo...",
  "service.PPT.generateAnOutline": "En el esquema de generación...",
  "service.PPT.rebuild": "Regeneración",
  "service.PPT.next": "Siguiente paso",
  "service.PPT.back": "Volver",
  "service.PPT.outlineDescription": "Puede hacer doble clic para editar el esquema o continuar como está",
  "service.PPT.chapter": "Capítulo",
  "service.PPT.language": "Idiomas",
  "service.PPT.pleaseSelectALanguage": "Por favor, elija el idioma",
  "service.PPT.size50": "El tamaño del PPT no puede exceder los 50m",
  "service.PPT.add": "Nuevo",
  "service.PPT.del": "Eliminar",
  "service.PPT.demote": "Desescalada",
  "service.PPT.selTheme": "Selección de temas",


  "service.record.list.digitalVoice": "Crear/Editar la voz digital",
  "service.record.list.name": "Nombre",
  "service.record.list.introduction": "Introducción",
  "service.record.list.voiceGender": "Género sonoro",
  "service.record.list.upload": "Cargar",
  "service.record.list.clickUpload": "Haga clic para subir",
  "service.record.list.agree": "He leído y estoy de acuerdo",
  "service.record.list.agreement": "Protocolo de creación y uso de la voz humana digital",
  "service.record.list.uploadType": "El formato es .mp3 .wav",
  "service.record.list.inputName": "Por favor, introduzca el nombre",
  "service.record.list.inputIntroduction": "Por favor, introduzca el perfil",
  "service.record.list.selectGender": "Por favor, elija el sexo",
  "service.record.list.uploadFile": "Por favor, suba el archivo",
  "service.record.list.pleaseAgree": "Por favor, acepte el acuerdo.",
  "service.record.list.generate": "Generar",
  "service.record.list.generateFailed": "Generación fallida",
  "service.record.list.gender.female": "Mujer",
  "service.record.list.gender.male": "Hombre",
  "service.record.list.selectVocal": "Seleccionar sonido digital",
  "service.record.list.selectModel": "Por favor, seleccione PPT, voz digital y persona digital",
  "service.record.list.onPublish": "en publicación...",
  "service.record.list.publish": "publicar",
  "service.record.list.requireVideoName": "el nombre del video es un campo obligatorio",
  "service.record.list.videoName": "nombre del video",
  "service.record.list.insert": "insertar",
  "service.record.list.ppt.uploadFailed": "la carga de ppt falló",
  "service.record.list.parsing": "en análisis...",
  "service.record.list.ppt.parseSuccess": "el análisis de ppt fue exitoso",
  "service.record.list.ppt.parseFailed": "el análisis de ppt falló",
  "service.record.list.selectAva": "Selección de avatares",
  "service.record.cameraTip": "No ha encendido la Cámara ni el micrófono, continúa?",
  "service.record.videoName": "Nombre del vídeo",


  "service.cost.amount": "cantidad consumida",
  "service.cost.type": "tipo de consumo",
  "service.cost.pieces": "número de piezas consumidas",
  "service.cost.time": "consumir tiempo",
  "service.cost.videoGenerate": "generación de vídeo",
  "service.cost.DH": "digital Man production",
  "service.cost.DV": "producción de sonido digital",
  "service.cost.searchTips": "por favor, elija la fuente de consumo",
  "service.cost.video": "Vídeo",
  "service.cost.vocal": "Sonido",


  "service.order.selRechargeSource": "por favor, elija la fuente de recarga",
  "service.order.selRechargeType": "por favor, elija el tipo de recarga",
  "service.order.selPaymentState": "por favor, elija el Estado de pago",
  "service.order.inputID": "por favor, introduzca el número de pedido",
  "service.order.recharge.wechat": "WeChat",
  "service.order.recharge.Alipay": "Alipay",
  "service.order.recharge.cloudquickpass": "cloud Flash pay",
  "service.order.payment.success": "pagado",
  "service.order.payment.failed": "no pagado",
  "service.order.invoice": "facturar",
  "service.order.id": "número de pedido",
  "service.order.rechargeSource": "fuente de recarga",
  "service.order.rechargeType": "tipo de recarga",
  "service.order.paymentState": "estado de pago",
  "service.order.recharge.CloudQuickPass": "Pago flash en la nube",
  "service.order.num": "número de pedidos",
  "service.order.orderTime": "tiempo de pedido",
  "service.order.payTime": "tiempo de pago",
  "service.order.video": "Vídeo",
  "service.order.digitalVoice": "Voz digital",


  "service.translation.selectLan": "Por favor, elija el idioma",
  "service.translation.selectVoice": "Por favor, elija el sonido",
  "service.translation.video": "Traducción de vídeo",
  "service.translation.originalLan": "Lengua original",
  "service.translation.selOriginalLan": "Por favor, elija el idioma original",
  "service.translation.translLan": "Idiomas de traducción",
  "service.translation.selTranslLan": "Por favor, elija el idioma de traducción",
  "service.translation.uploadVideo": "Sube el video",
  "service.translation.uploadVideoVail": "Por favor, suba el video",
  "service.translation.uploadSuccess": "La carga fue exitosa",
  "service.translation.uploadFailed": "Falló la carga",
  "service.translation.generating": "En la generación de traducción",
  "service.translation.wait": "Por favor, espere...",


  "service.login.login": "iniciar sesión",
  "service.login.logout": "salir",
  "service.login.register": "Registro",
  "service.login.registerTips": "Cuenta registrada",

  "service.component.digitalVoice": "Voz digital",
  "service.component.digitalFigures": "Hombre digital",
  "service.component.ppt": "Generar PPT",
  "service.component.onGenerate": "En generación",
  "service.component.timeEstimate": "Presupuesto de tiempo",
  "service.component.timeEstimateDetail": "Se espera que tome XXX minutos y XXX yuanes.",
  "service.component.paySuccess": "Pago exitoso",

  "service.common.publishSuccess": "Lanzamiento exitoso",
  "service.common.deleteSuccess": "Eliminación exitosa",
  "service.common.deleteFail": "Falló la eliminación",
  "service.common.saveSuccess": "Guardar con éxito",
  "service.common.saveFail": "Guardar falló",
  "service.common.success": "éxito",
  "service.common.Edit": "Edición",
  "service.common.confirm": "Confirmación",
  "service.common.cancel": "Cancelación",
  "service.common.setting": "Configuración",
  "service.common.None": "Aún no",
  "service.common.tip": "Consejos",
  "service.common.title": "Título",
  "service.common.enterTitle": "Por favor, introduzca el título",
  "service.common.theBuildSucceeded": "Generación exitosa",
  "service.common.downloadFailed": "Falló la descarga",


  "service.notice.errorRequest": "请求错误: ",
  "service.notice.timeout": "请求超时，请检查网络或稍后再试",
  "service.notice.request202": "已接受请求，并进入后台排队（异步任务），但未处理完成",
  "service.notice.request203": "请求成功，非授权信息，返回的meta信息不在原始的服务器，而是一个副本",
  "service.notice.request204": "服务器成功处理，但未返回内容",
  "service.notice.request205": "服务器成功处理，客户端（浏览器）应重置页面视图",
  "service.notice.request206": "服务器成功处理了部分GET请求",
  "service.notice.request400": "发出的请求有误（客户端请求语法错误），服务器没有进行新建或修改数据的操作",
  "service.notice.request401": "用户没有权限（令牌、用户名、密码错误）或登录过期",
  "service.notice.request403": "用户得到授权，但访问已被禁止",
  "service.notice.request404": "请求的资源无法找到（资源不存在或URL错误），服务器没有进行操作",
  "service.notice.request405": "客户端请求中的方法被服务器禁止或请求方法错误",
  "service.notice.request406": "客户端请求格式错误或服务端返回内容客户端无法解析",
  "service.notice.request410": "请求的资源被永久删除，且不会再得到",
  "service.notice.request412": "客户端请求信息的先决条件错误",
  "service.notice.request413": "由于请求的实体过大，服务器无法处理，因此拒绝请求",
  "service.notice.request414": "请求的URL过长（URL通常为网址），服务器无法处理",
  "service.notice.request415": "服务器无法处理请求附带的媒体格式",
  "service.notice.request416": "客户端请求的范围无效",
  "service.notice.request422": "当创建一个对象时，发生一个验证错误",
  "service.notice.request500": "服务器发生错误，请检查服务器",
  "service.notice.request501": "服务器不支持请求的功能，无法完成请求",
  "service.notice.request502": "网关错误，从远程服务器接收到了一个无效的响应",
  "service.notice.request503": "服务不可用，服务器暂时过载或维护",
  "service.notice.request504": "网关超时，充当网关或代理的服务器，未及时从远端服务器获取请求",
  "service.notice.request505": "服务器不支持请求的HTTP协议的版本，无法完成处理",
  "service.notice.request604": "登录超时，请重新登录",
  "service.notice.request603": "登录超时，请重新登录",


  "service.username": "Nombre de usuario",
  "service.email": "Buzón",
  "service.phone": "Número de teléfono móvil",
  "service.organization": "Organización",
  "service.OK": "Determinar",


  "tips.login.notSupport": "No hay apoyo por el momento",
  "tips.login.success": "Inicio de sesión exitoso",
  "tips.login.fail": "Falló el inicio de sesión",
  "tips.login.acc": "Cuenta",
  "tips.login.pass": "Contraseña",
  "tips.login.agreeTips": "Si está de acuerdo con el Acuerdo de privacidad del usuario",
  "tips.login.account": "Por favor, ingrese a la cuenta",
  "tips.login.password": "Por favor, introduzca la contraseña",
  "tips.login.loginWithVerify": "Iniciar sesión con el Código de verificación",
  "tips.login.check": "Ver",
  "tips.privacy.title": "Protocolo de privacidad del usuario",
  "tips.update.success": "Modificado con éxito",
  "tips.update.error": "Modificación fallida",
  "tips.logOff.success": "Ha cancelado",
  "tips.logOff.error": "Falló la cancelación",
  "tips.logOff.warning": "Está seguro de que quiere cancelar?",
  "tips.email.format": "Introduzca el buzón correcto",
  "tips.file.size5": "El tamaño del archivo no puede exceder el 5m",
  "tips.logOff.tips": "No se puede restaurar después de la cancelación, por favor, tenga cuidado",
  "tips.psw.length": "La longitud de la contraseña debe estar entre 6 - 20 dígitos",
  "tips.psw.chars": "La contraseña debe contener dos de las letras mayúsculas y minúsculas, números y caracteres especiales",
  "tips.psw.rule": "Introduzca una nueva contraseña, con una longitud de 6 - 20 dígitos, que debe contener dos de mayúsculas, números y caracteres especiales",
  "tips.download.warning": "Se está descargando, no haga clic repetidamente!",
  "tips.login.theAccountNumberOrPasswordIsIncorrect": "Cuenta o contraseña incorrecta!",

  "btn.text.release": "Lanzamiento",
  "btn.text.modify": "Modificación",

  "service.user.center": "Centro de usuarios",
  "service.user.updateAvatar": "Modificar el avatar",
  "service.user.avatarFormat": "La imagen debe tener más de 500*500 píxeles, admitir formatos JPG, JPEG, PNG y SVG, y el tamaño no debe superar los 5M",
  "service.user.nickname": "Apodo",
  "service.user.cancelAccount": "Cancelar cuenta",
  "service.user.cancelAccountWarning": "No se puede recuperar después de cerrar la sesión, por favor opere con precaución",
  "service.user.logOff": "Cerrar sesión",
  "service.user.changePsw": "Modificar la contraseña",

}

export default esLanguage;

// 路由配置
import React, {lazy} from "react";

import {Navigate} from 'react-router-dom'
import Icon from "@ant-design/icons/lib/components/Icon";
import IconComp from "@/components/IconComp";
import Loading from "@/components/Loading";
import Error from "@/components/Error404";
import {ComponentSvg} from "@/assets/icons/component";
// import componentSvg from "@/assets/icons/component.svg";
// import { componentSvg as MyIcon } from '@/assets/icons/component.svg';

const LoginPage = lazy(() => import("@/pages/Login"));
const CodeLoginPage = lazy(() => import("@/pages/CodeLogin"));
const PrivacyPage = lazy(() => import("@/pages/Privacy"));
const HomePage = lazy(() => import('@/pages/Home'));
const ComponentPage = lazy(() => import("@/pages/SourceMaterial"));

const VideoPage = lazy(() => import("@/pages/Video"));
const SwSdkPage = lazy(() => import("@/pages/SwSdk"));
const AiRecordListPage = lazy(() => import("@/pages/AiRecordList"));
const AiVideoPage = lazy(() => import("@/pages/AiRecordList/comp/aiVideo"));
const AvatarPage = lazy(() => import("@/pages/SourceMaterial/Avatar"));
const VoicePage = lazy(() => import("@/pages/SourceMaterial/Voice"));
const PptPage = lazy(() => import("@/pages/SourceMaterial/Ppt"));
const WpsPptPage = lazy(() => import("@/pages/SourceMaterial/Ppt/comp/wps"));
const PowerPointOutlinePage = lazy(() => import("@/pages/SourceMaterial/Ppt/comp/outline"));

const MatualPage = lazy(() => import("@/pages/MatualRecordList"));
const TransPage = lazy(() => import("@/pages/Translation"));


const OrganizationPage = lazy(() => import("@/pages/Organization"));


const OrderPage = lazy(() => import("@/pages/User/order"))
const CostPage = lazy(() => import("@/pages/User/cost"));

const AccountPage = lazy(() => import("@/pages/User/account"));


/**
 * 包裹一层loading
 * @param routeItem
 */
const withLoading = (routeItem) => (<React.Suspense fallback={<Loading/>}>{routeItem}</React.Suspense>)


// 配置路由实例

const route = [
    {
        path: "/",
        element: <Navigate to="/login"/>,
        meta: {
            showInMenu: false,
        }
    },
    {
        label: "用户隐私协议",
        path: "/privacy",
        element: withLoading(<PrivacyPage/>),
        disabled: false,
        meta: {
            showInMenu: false,
        }
    },
    {
        path: "/login",
        element: withLoading(<LoginPage/>),
        meta: {
            showInMenu: false,
        }
    },
    {
        path: "/codeLogin",
        element: withLoading(<CodeLoginPage/>),
        meta: {
            showInMenu: false,
        }
    },
    {
        path: "/home",
        element: withLoading(<HomePage/>),
        meta: {
            label: "service.menu.home",
            disabled: false,
            showInMenu: true,
            // () => <IconComp name="component" fill="white" width="22px" height="22px"/>
            icon: <Icon component={ComponentSvg}/>
        }
    },

    {
        path: "/organization",
        element: withLoading(<OrganizationPage/>),
        meta: {
            label: "service.menu.organManage",
            disabled: false,
            showInMenu: false
        }
    },
    {
        path: "/component",
        element: withLoading(<ComponentPage/>),
        redirect: "/component/voice",
        meta: {
            label: "service.menu.component",
            disabled: false,
            icon: <Icon component={() => <IconComp name="component" fill="white" width="22px" height="22px"/>}/>,
            showInMenu: true,
        },
        children: [
            {
                path: "/component/voice",
                element: withLoading(<VoicePage/>),
                meta: {
                    label: "声音",
                    showInMenu: false,
                    disabled: false,
                }
            },
            {
                path: "/component/avatar",
                element: withLoading(<AvatarPage/>),
                meta: {
                    label: "视频",
                    showInMenu: false,
                    disabled: false,
                },
            },
            {
                path: "/component/ppt",
                element: withLoading(<PptPage/>),
                meta: {
                    label: "PPT",
                    showInMenu: false,
                    disabled: false,
                }
            }
        ],
    },
    {
        path: "/component/ppt/office",
        element: withLoading(<WpsPptPage/>),
        meta: {
            label: "service.menu.component.PPT",
            showInMenu: true,
            disabled: false,
        },
    },
    {
        path: "/component/ppt/outline",
        element: withLoading(<PowerPointOutlinePage/>),
        meta: {
            label: "service.menu.component.PPT",
            showInMenu: true,
            disabled: false,
        },
    },
    {
        path: "/dashboard",
        meta: {
            label: "service.menu.workBench",
            showInMenu: true,
            disabled: false,
            icon: <Icon component={() => <IconComp name="dashboard" fill="white" width="22px" height="22px"/>}/>,
        },
        redirect: "/dashboard/aiVideo",
        children: [
            {
                path: "/dashboard/aiVideo",
                meta: {
                    label: "service.menu.AIRecord",
                    showInMenu: true,
                    disabled: false,
                },
                element: withLoading(<AiRecordListPage/>),
            },
            {
                path: "/dashboard/aiRecord",
                meta: {
                    label: "录制视频",
                    showInMenu: false,
                    disabled: false,
                },
                element: withLoading(<AiVideoPage/>),
            },

            {
                path: "/dashboard/video",
                meta: {
                    label: "service.menu.manualRecord",
                    showInMenu: true,
                    disabled: false,
                },
                //     element: withLoading(<VideoPage/>),
                element: withLoading(<MatualPage/>),

            },
            {
                path: "/dashboard/record",
                meta: {
                    label: "录制视频",
                    showInMenu: false,
                    disabled: false,
                },
                // element: withLoading(<SwSdkPage/>)
                element: withLoading(<VideoPage/>),
            },
          {
            path: "/dashboard/translation",
            meta: {
              label: "service.menu.Translation",
              showInMenu: true,
              disabled: false,
            },
            //     element: withLoading(<VideoPage/>),
            element: withLoading(<TransPage/>),

          },

        ],
        disabled: false,
    },

    {
        path: "/user/order",
        meta: {
            showInMenu: true,
            label: "service.my.order",
            disabled: false,
        },
        element: withLoading(<OrderPage/>)
    },

    {
        path: "/user/cost",
        meta: {
            showInMenu: true,
            label: "service.my.cost",
            disabled: false,
        },
        element: withLoading(<CostPage/>)
    },
    {
        path: "/user/account",
        meta: {
            showInMenu: true,
            label: "service.my.account",
            disabled: false,
        },
        element: withLoading(<AccountPage/>)
    },
    // {
    //     path: "/user",
    //     redirect: "/user/order",
    //     element: () => (<><Outlet></Outlet></>),
    // children: [{
    //     path: "/user/order",
    //     meta: {
    //         label: "我的订单",
    //         showInMenu: true,
    //         disabled: false,
    //     },
    //     element: () => import("@/pages/User/order")
    // }, {
    //     path: "/user/cost",
    //     meta: {
    //         label: "我的消耗",
    //         showInMenu: true,
    //         disabled: false,
    //     },
    //     element: () => import("@/pages/User/order")
    // }, {
    //     path: "/user/account",
    //     meta: {
    //         label: "我的账号",
    //         showInMenu: true,
    //         disabled: false,
    //     },
    //     element: () => import("@/pages/User/order")
    // }]import { lazy } from 'react';

    // },
    {
        path: "/*",
        element: withLoading(<Error/>),
        meta: {
            showInMenu: false,
        }
    }

]
export default route;

import {useEffect} from "react";
import {notification} from "antd";
import {createRoot} from "react-dom/client";

/**
 * 通知组件
 * @param type 类型
 * @param message 信息
 * @param description 描述
 * @constructor
 */
function NoticeComp({type, message, description}) {
    // 根据通知的类型来设置样式
    useEffect(() => {
        notification.open({
            type,
            message,
            description,
            duration: 1,
            closeIcon: null,
            className: 'notice'
        })
    }, [type, message, description]);
    return <></>;
}

/**
 * 发布一个通知，调用antd的notification发布通知，默认3秒后删除
 * @param type 通知类型 success | error | info | warning | open | destroy
 * @param content 通知内容内容
 * @param description 描述 默认为空
 * @param keepTime 指定移除时间，默认3秒
 * @constructor
 */
export default function publishNotice(type, content, description = '', keepTime = 3000) {
    const div = document.createElement('div');
    document.body.appendChild(div);
    const root = createRoot(div);
    root.render(<NoticeComp type={type} message={content} description={description}/>);

    // 在一段时间后移除通知
    setTimeout(() => {
        root.unmount();
        document.body.removeChild(div);
    }, keepTime);
}
